import '../styles/main.scss';

import Dropzone from "dropzone";
import JustValidate from 'just-validate';

import {parsePhoneNumber, AsYouType} from 'libphonenumber-js/mobile';

const checkbox = document.querySelector("input#agreement_confirm");
const button = document.querySelector("#submit_mainpage");

if (checkbox) {
    checkbox.addEventListener('change', function () {
        if (this.checked) {
            button.classList.remove('disabled');
        } else {
            button.classList.add('disabled');
        }
    });
}


import {Tooltip} from 'bootstrap';

const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bss-tooltip]'));
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
});



const FormErrorDictionary = {
    Required: "Pole wymagane",
    Email: "email",
    MinLength: "minLength",
    // MaxLength = "maxLength",
    // Password = "password",
    // Number = "number",
    // MaxNumber = "maxNumber",
    // MinNumber = "minNumber",
    // StrongPassword = "strongPassword",
    // CustomRegexp = "customRegexp",
    // MinFilesCount = "minFilesCount",
    // MaxFilesCount = "maxFilesCount",
    // Files = "files"
}
const formPrefix = '#form_wniosek';
const form = document.querySelector(formPrefix);
if (form) {
    const validation = new JustValidate(formPrefix, {
        focusInvalidField: true,
        errorFieldStyle: '',
        errorFieldCssClass: ['is-invalid'],
        errorLabelStyle: '',
        errorLabelCssClass: ['invalid-feedback', 'd-block'],
    });

    const sendAddressContainer = document.querySelector('#send_address_container');
    const sendAddressDetailsContainer = document.querySelector('#send_address_details_container');


    const phoneNumberInput = document.querySelector('#form_wniosek_phoneNumber');

    phoneNumberInput.dispatchEvent(new Event('change'));

    const documentDeliveryCheckbox = document.querySelector('#form_wniosek_documentDelivery');
    const vatInvoiceCheckbox = document.querySelector('#form_wniosek_vatInvoice');
    const sendAddressSameAsInvoiceCheckbox = document.querySelector('#form_wniosek_sendAddressSameAsInvoice');


    // zaznaczenie - chce otrzymac fakture
    vatInvoiceCheckbox && vatInvoiceCheckbox.addEventListener('change', (event) => {
        if(sendAddressSameAsInvoiceCheckbox) {
            sendAddressSameAsInvoiceCheckbox.checked = false;
        }

        if (event.target.checked) {
            // odblokuj przycisk
            if(sendAddressSameAsInvoiceCheckbox) {
                sendAddressSameAsInvoiceCheckbox.parentNode.parentNode.parentNode.classList.remove('d-none');
            }

            validation.addField(formPrefix+'_nip', [
                {
                    rule: 'required',
                    errorMessage: FormErrorDictionary.Required,
                },
                {
                    rule: 'customRegexp',
                    value: /^[0-9]{10}$/,
                    errorMessage: 'Niepoprawny numer NIP'
                }
            ]);

            validation.addField(formPrefix+'_invoiceCompanyName', [
                {
                    rule: 'required',
                    errorMessage: FormErrorDictionary.Required,
                }
            ]);

            validation.addField(formPrefix+'_invoiceStreet', [
                {
                    rule: 'required',
                    errorMessage: FormErrorDictionary.Required,
                }
            ]);

            validation.addField(formPrefix+'_invoiceBuildingNumber', [
                {
                    rule: 'required',
                    errorMessage: FormErrorDictionary.Required,
                }
            ]);

            validation.addField(formPrefix+'_invoicePostCode', [
                {
                    rule: 'required',
                    errorMessage: FormErrorDictionary.Required,
                },
                {
                    rule: 'customRegexp',
                    value: /^[0-9]{2}\-?[0-9]{3}$/,
                    errorMessage: 'Niepoprawny kod pocztowy'
                }
            ]);

            validation.addField(formPrefix+'_invoiceCity', [
                {
                    rule: 'required',
                    errorMessage: FormErrorDictionary.Required,
                }
            ]);


        } else {
            validation.removeField(formPrefix+'_nip');
            validation.removeField(formPrefix+'_invoiceCompanyName');
            validation.removeField(formPrefix+'_invoiceStreet');
            validation.removeField(formPrefix+'_invoiceBuildingNumber');
            validation.removeField(formPrefix+'_invoicePostCode');
            validation.removeField(formPrefix+'_invoiceCity');

            // ukryj przycisk
            if(sendAddressSameAsInvoiceCheckbox) {
                sendAddressSameAsInvoiceCheckbox.parentNode.parentNode.parentNode.classList.add('d-none');
            }
        }
    });

    function addSenderAddressValidation() {
        if(!documentDeliveryCheckbox.checked) {

            return;
        }

        if(document.querySelector(formPrefix+'_sendAddressCompanyName')) {
            validation.addField(formPrefix + '_sendAddressCompanyName', [
                {
                    rule: 'required',
                    errorMessage: FormErrorDictionary.Required,
                }
            ]);
        }

        validation.addField(formPrefix+'_sendAddressStreet', [
            {
                rule: 'required',
                errorMessage: FormErrorDictionary.Required,
            }
        ]);

        validation.addField(formPrefix+'_sendAddressBuildingNumber', [
            {
                rule: 'required',
                errorMessage: FormErrorDictionary.Required,
            }
        ]);

        validation.addField(formPrefix+'_sendAddressPostCode', [
            {
                rule: 'required',
                errorMessage: FormErrorDictionary.Required,
            },
            {
                rule: 'customRegexp',
                value: /^[0-9]{2}\-?[0-9]{3}$/,
                errorMessage: 'Niepoprawny kod pocztowy'
            }
        ]);

        validation.addField(formPrefix+'_sendAddressCity', [
            {
                rule: 'required',
                errorMessage: FormErrorDictionary.Required,
            }
        ]);
    }

    function removeSendAddressValidation() {
        if(document.querySelector(formPrefix+'_sendAddressCompanyName')) {
            validation.removeField(formPrefix + '_sendAddressCompanyName');
        }

        validation.removeField(formPrefix+'_sendAddressStreet');
        validation.removeField(formPrefix+'_sendAddressBuildingNumber');
        validation.removeField(formPrefix+'_sendAddressPostCode');
        validation.removeField(formPrefix+'_sendAddressCity');
    }

    // zaznaczenie wysylka dokumentu
    documentDeliveryCheckbox && documentDeliveryCheckbox.addEventListener('change', (event) => {
        if (event.target.checked) {
            sendAddressContainer.classList.remove('d-none');
            sendAddressContainer.classList.add('d-flex');
            sendAddressContainer.querySelectorAll('input:not(#form_wniosek_sendAddressSameAsInvoice)').forEach((item) => {
                if (item.previousSibling.classList.contains('required')) {
                    item.required = true;
                }
            });

            if(!sendAddressSameAsInvoiceCheckbox || !sendAddressSameAsInvoiceCheckbox.checked) {
                addSenderAddressValidation();
            }

        } else {
            sendAddressContainer.classList.remove('d-flex');
            sendAddressContainer.classList.add('d-none');
            sendAddressContainer.querySelectorAll('input:not(#form_wniosek_sendAddressSameAsInvoice)').forEach((item) => {
                item.value = null;
                item.required = false;
            });

            removeSendAddressValidation();
        }
    });

    const totalCost = document.querySelector('#total_cost');
    const documentDeliveryPrice = document.querySelector('#delivery-price');

    documentDeliveryCheckbox && documentDeliveryCheckbox.addEventListener('change', (event) => {
        let cost = 0;

        documentDeliveryPrice.classList.remove('d-none');

        if (!event.target.checked) {
            documentDeliveryPrice.classList.add('d-none');
        }

        document.querySelectorAll('[data-price]:not(.d-none)').forEach((item) => {
            cost += parseInt(item.dataset.price, 10);
        });

        totalCost.innerHTML = cost;
    });

    sendAddressSameAsInvoiceCheckbox && sendAddressSameAsInvoiceCheckbox.addEventListener('change', (event) => {
        if (!event.target.checked) {
            sendAddressContainer.querySelectorAll('input').forEach((item) => {
                item.readOnly = false;
            });

            addSenderAddressValidation();

            sendAddressDetailsContainer.classList.remove('d-none');


        } else {
            sendAddressContainer.querySelectorAll('input').forEach((item) => {
                item.value = null;
                item.readOnly = true;
            });

            removeSendAddressValidation();

            sendAddressDetailsContainer.classList.add('d-none');
        }
    })

    // handle on load
    let event = new Event('change');
    vatInvoiceCheckbox && vatInvoiceCheckbox.dispatchEvent(new Event('change'));
    documentDeliveryCheckbox && documentDeliveryCheckbox.dispatchEvent(new Event('change'));
    sendAddressSameAsInvoiceCheckbox && sendAddressSameAsInvoiceCheckbox.dispatchEvent(event);


    function createDropzone(element) {
        let myDropzone = new Dropzone(element, {
            url: "/upload-image",
            createImageThumbnails: false,
            disablePreviews: true,
            acceptedFiles: 'image/*,application/pdf,.jpeg,.jpg,.png,.pdf',
            accept: {
                'image/png': ['.png'],
                'image/jpeg': ['.jpg', '.jpeg'],
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
            },
        });


        let imageContainerTarget = document.querySelector(myDropzone.element.dataset.target);
        let imageContainerTargetInput = document.querySelector(myDropzone.element.dataset.targetInput);
        imageContainerTarget.querySelector('a.remove-image').addEventListener('click', function (event) {

            myDropzone.element.classList.remove('disabled');
            imageContainerTarget.querySelector('.filename').textContent = '';
            imageContainerTarget.classList.add('d-none');
            imageContainerTargetInput.value = null;

            event.preventDefault();

            return false;
        });

        myDropzone
            .on('addedfile', function (file) {
                this.element.classList.add('disabled');
            })
            .on('success', function (file) {
                imageContainerTarget.querySelector('.filename').textContent = file.name;
                imageContainerTarget.classList.remove('d-none');

                let resp = JSON.parse(file.xhr.response);
                imageContainerTargetInput.value = resp.filename;

                validation.revalidateField('#' + imageContainerTargetInput.id);
            })
            .on('error', function (file, message) {
                this.element.classList.remove('disabled');

                if(message.error) {
                    alert('Wystąpił błąd podczas wgrywania pliku. ' + message.error);
                } else {
                    alert('Wystąpił błąd podczas wgrywania pliku. Spróbuj ponownie.');
                }
            });
    }

    let image1 = document.querySelector('#add_picture');
    let image2 = document.querySelector('#add_picture-2');
    let image3 = document.querySelector('#add_picture-3');

    if (image1) {
        createDropzone(image1);
    }

    if (image2) {
        createDropzone(image2);
    }

    if (image3) {
        createDropzone(image3);
    }

    if(document.querySelector(formPrefix + '_firstName')) {

        validation
            .addField(formPrefix + '_firstName', [
                {
                    rule: 'required',
                    errorMessage: FormErrorDictionary.Required,
                },
                {
                    rule: 'minLength',
                    value: 3,
                    errorMessage: 'Imię musi mieć minimum 3 znaki',
                },
                {
                    rule: 'maxLength',
                    value: 30,
                    errorMessage: 'Imię może mieć maksymalnie 30 znaków',
                },
            ]);
    }

    if(document.querySelector(formPrefix + '_lastName')) {
        validation.addField(formPrefix + '_lastName', [
            {
                rule: 'required',
                errorMessage: FormErrorDictionary.Required,
            },
            {
                rule: 'minLength',
                value: 3,
                errorMessage: 'Nazwisko musi mieć minimum 3 znaki',
            },
            {
                rule: 'maxLength',
                value: 30,
                errorMessage: 'Nazwisko może mieć maksymalnie 30 znaków',
            },
        ]);
    }

    validation
        .addField(formPrefix + '_email', [
            {
                rule: 'required',
                errorMessage: FormErrorDictionary.Required,
            },
            {
                rule: 'email',
                errorMessage: 'Niepoprawny adres e-mail',
            },
        ])
        .addField(formPrefix + '_vin', [
            {
                rule: 'required',
                errorMessage: FormErrorDictionary.Required,
            },
            {
                rule: 'minLength',
                value: 17,
                errorMessage: 'VIN powinien mieć dokładnie 17 znaków.',
            },
            {
                rule: 'maxLength',
                value: 17,
                errorMessage: 'VIN powinien mieć dokładnie 17 znaków.',
            },
            {
                validator: (value) => {
                    return value.indexOf('o') === -1 && value.indexOf('O') === -1;
                },
                errorMessage: 'Zamiast litery "O", wpisz cyfrę "zero"',
            }
        ])
        .addField(formPrefix + '_phoneNumber', [
            {
                rule: 'required',
                errorMessage: FormErrorDictionary.Required,
            },
            {
                validator: (value) => {
                    try {
                        const phoneNumber = parsePhoneNumber(value, 'PL');

                        if (phoneNumber && phoneNumber.country === 'PL' && phoneNumber.isValid() && phoneNumber.getType() === 'MOBILE') {
                            return true;
                        }
                    } catch(e) {

                    }

                    return false;
                },
                errorMessage: 'Niepoprawny numer telefonu',
            },
        ])
        .addField(formPrefix + '_image', [
            {
                rule: 'required',
                errorMessage: FormErrorDictionary.Required,
            },
        ])
        .addField(formPrefix + '_image2', [
            {
                rule: 'required',
                errorMessage: FormErrorDictionary.Required,
            },
        ])
        .addField(formPrefix + '_image3', [
            {
                rule: 'required',
                errorMessage: FormErrorDictionary.Required,
            },
        ])
        .addField(formPrefix + '_terms', [
            {
                rule: 'required',
                errorMessage: FormErrorDictionary.Required,
            },
        ])
        .onSuccess((event) => {
            document.querySelector(formPrefix).submit();
        });

    document.querySelectorAll('.is-invalid').forEach(function (el) {
        validation.errorLabels['#' + el.id] = el.nextElementSibling;
    })

    //createDropzone('#add_picture-2');
}
